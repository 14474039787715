import React, { useState } from "react"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Alert, AlertTitle } from "@material-ui/lab"

import axios from "axios"

export default function ContactPage() {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    if (!email || !message) {
      alert("Please insert Email and Message")
      return
    }

    setLoading(true)
    setError(false)
    setSuccess(false)


    axios
      .post("https://us-central1-freedsoundmp3.cloudfunctions.net/mail/send", {
        email,
        message,
      })
      .then(res => {
        console.log("res", res)
        if (res.data.error) {
          setError(true)
          setLoading(false)
          return
        }
        setSuccess(true)
        setLoading(false)
      })
      .catch(err => {
        setError(true)
        setLoading(false)
      })
  }

  return (
    <Layout>
      <SEO title={t("contact")} description={t("contactText")} />

      <Container maxWidth="sm">
        <div className="text-center" style={{ marginTop: 50 }}>
          <h1>
            <Trans>contact</Trans>
          </h1>
          <p>
            <Trans>contactText</Trans>
          </p>
        </div>

        {success && (
          <div style={{ marginTop: 50 }}>
            <Alert severity="success">
              <AlertTitle>
                <Trans>contactSuccessTitle</Trans>
              </AlertTitle>
              <Trans>contactSuccessText</Trans>
            </Alert>
          </div>
        )}

        {!success && (
          <form
            id="contactForm"
            style={{ marginTop: 40 }}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  onChange={e => setEmail(e.target.value)}
                  required
                  type="email"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="message"
                  label={t("message")}
                  multiline
                  rows={6}
                  variant="outlined"
                  fullWidth
                  onChange={e => setMessage(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                >
                  {!loading && <Trans>send</Trans>}

                  {loading && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress size={15} color="primary" />
                    </div>
                  )}
                </Button>

                {error && (
                  <div>
                    <p style={{ color: "red" }}>
                      Error, please try again later
                    </p>
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Container>
    </Layout>
  )
}
